import styled from 'styled-components'

export const DropDown = styled.div`  
  width:100%;
  padding:10px 0;
  position: absolute;
  top:100%;
  right:0;
  z-index:1;

  background: #fff;
  box-shadow: 0px 12px 18px rgba(49, 57, 62, 0.4);
  border-radius: 20px;
  border:1px solid #ABCADC;
  min-width: 290px;
  max-width: 290px;
  transform: scale(0.8) translateY(-12%);

  display: block;
  visibility: hidden;
  opacity: 0;
  transition: 0.4s cubic-bezier(0.3, 0, 0, 1.3);
  
  &.active{
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    transform: none;
  }
`
export const DropDownHeader = styled.div`    
  padding:15px;
  @media (min-width: 992px) {
    padding:20px;
  }
  + .dropdown-menu{
    padding-top:0;
  }
`
export const DropDownTitle = styled.div`    
  font-size:18px;
  line-height:30px;  
  font-weight:500;
  color:#062C44;
  border-bottom:1px solid #ABCADC;
  padding-bottom:5px;
  @media (min-width: 992px) {
    padding-bottom:10px;
  }
`
export const DropDownMenu = styled.div`    
  padding:15px;
  @media (min-width: 992px) {
    padding:20px;
  }
`

export const DropDownList = styled.ul`
  list-style:none;
  margin:0;
  padding:0;
  transition:all 0.5s ease;
  @media screen and ( max-height: 680px ){
    max-height: 438px;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    margin-right:15px;
  
    ::-webkit-scrollbar {
      width: 6px;
    }
  
    ::-webkit-scrollbar-track {
      background: #f1f1f1; 
      border-radius:6px;
    }
  
    ::-webkit-scrollbar-thumb {
        background: #999; 
        border-radius:6px;
    }
  
    ::-webkit-scrollbar-thumb:hover {
      background: #666; 
    }
  }

`
export const DropDownListItem = styled.li`
  position:relative;
  margin:0;
  padding:5px 0; 
`

