import React from "react"
import {
  ToolbarList,
  Showing,
  Pages,
  PageList,
  PageItem,
  PerPage,
} from "../ToolbarStyle"

const Pagination = ({
  count,
  pageNo,
  changePageNo,
  pageSize,
  changePageSize,
  scrollToStart,
}) => {
  if (count === 0) return null

  const numberOfPages = Math.ceil(count / pageSize)
  const startIndex = (pageNo - 1) * pageSize
  const endIndex = startIndex + pageSize

  let beforeDot = 0
  let afterDot = 0

  const renderPageList = () => {
    if (count <= pageSize) return null

    return (
      <Pages className="pages">
        <PageList role="navigation" aria-label="Pagination">
          {pageNo > 1 && (
            <PageItem
              className="page next"
              onClick={() => {
                changePageNo(pageNo - 1)
                scrollToStart()
              }}
            >
              <span>Prev</span>
            </PageItem>
          )}
          {Array.from({ length: numberOfPages }).map((_, i) => {
            if (i + 1 > 1 && i < pageNo - 2) {
              beforeDot = beforeDot + 1
              if (beforeDot === 1) {
                return <span key={i}>...</span>
              } else {
                return null
              }
            } else if (i + 1 > pageNo + 1 && i + 1 < numberOfPages) {
              afterDot = afterDot + 1
              if (afterDot === 1) {
                return <span key={i}>...</span>
              } else {
                return null
              }
            } else {
              return (
                <PageItem className="page" key={i}>
                  {pageNo === i + 1 ? (
                    <span className="active">{i + 1}</span>
                  ) : (
                    <span
                      onClick={() => {
                        changePageNo(i + 1)
                        scrollToStart()
                      }}
                    >
                      <span className="selectable-pageno">{i + 1}</span>
                    </span>
                  )}
                </PageItem>
              )
            }
          })}
          {pageNo < numberOfPages && (
            <PageItem
              className="page next"
              onClick={() => {
                changePageNo(pageNo + 1)
                scrollToStart()
              }}
            >
              <span>Next</span>
            </PageItem>
          )}
        </PageList>
      </Pages>
    )
  }

  return (
    <ToolbarList className="toolbar-list">
      <Showing className="showing">
        <span>
          Showing {startIndex + 1} - {endIndex > count ? count : endIndex} of{" "}
          <strong>{count}</strong> Products{" "}
        </span>
      </Showing>
      {renderPageList()}
      {/* <PerPage className="per-page">
        <a className="disabled">Per page:</a>
        {[15, 30, 60].map((ps, i) => (
          <span
            key={i}
            className={pageSize === ps ? "page-size active" : "page-size"}
            onClick={() => {
              changePageSize(ps)
              scrollToStart()
            }}
          >
            {ps}
          </span>
        ))}
      </PerPage> */}
    </ToolbarList>
  )
}
export default Pagination