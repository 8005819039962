import styled from "styled-components"

export const Toolbar = styled.div`
  margin: 0 -5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`
export const ToolbarItems = styled.div`
  position: relative;
  padding: 0 5px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`
export const ToolbarToggleGrid = styled.div`
   display: flex;
  position: relative;
  padding: 0 5px;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
  @media (max-width: 767.98px) {
    display: none;
  }
  .toggle-grid-button {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background: #eeeeee;
    padding: 6px;
    .toggle-grid-icon {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 18px;
      height: 18px;
      > svg {
        height: 18px;
        fill: #888;
      }
    }
    .toggle-grid-text {
      display: inline-flex;
      font-size: 10px;
      line-height: 12px;
    }
    &:hover {
      svg {
        fill: #000;
      }
    }

    &.active {
      svg {
        fill: #000;
      }
    }
  }
`
export const ToolbarList = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  justify-content: right;
  padding: 5px;
  @media (min-width: 992px) {
    padding: 5px 10px;
  }
`;
export const Showing = styled.div`
  color: #8CB7CE;
  display: none;
  @media (min-width: 992px) {
    display: flex;
  }

  strong {
    font-weight: 500;
    color: #062c44;
  }
`;
export const PerPage = styled.div`
  flex-wrap: wrap;
  margin: 0 -5px;
  display: none;
  @media (min-width: 576px) {
    display: flex;
  }
  > a,
  .page-size {
    position: relative;
    font-weight: 600;
    padding: 0 5px;
    color: #8CB7CE;
    &:hover {
      color: #000;
    }
    &.disabled {
      color: #8CB7CE;
      font-weight: 400;
    }
    &.active {
      color: #000;
    }
  }

  .page-size {
    cursor: pointer;
  }
`;
export const Pages = styled.div`
  @media (min-width: 992px) {
    padding:0 0 0 15px;
    margin:0 0 0 15px;
    border-left:1px solid #8CB7CE;
  }
`;

export const PageList = styled.div`
  list-style: none;
  margin: 0 -5px;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #8CB7CE;
`;
export const PageItem = styled.div`
  position: relative;
  padding: 0 5px;

  .selectable-pageno{
    cursor: pointer;
    color: #8CB7CE;
    &:hover{
      color: #062c44;
    }
  }
  &.page {
    & .active {
      color: #062c44;
    }
  }
  &.next, &.prev{
    > span{
      cursor: pointer;
      color: #8CB7CE;
      &:hover {
        color: #062c44;
      }
    }
  }
 
`;
