import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { createClient } from "contentful-management"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Container,
  SectionDescription,
  SectionPage,
  SectionPageTitle,
} from "../components/Section"
import axios from "axios"
import CallToAction from "../components/CallToAction"
import PrimaryButton from "../components/Button/PrimaryButton"
import Products from "../sections/Product/Products"

const SyncButton = styled.div`
  display: none;
  text-align: center;
  width: fit-content;
  margin: 2rem auto;

  &:hover {
    cursor: pointer;
  }
`

const ProductPage = pageData => {
  const [seseiProductsData, setSenseiProductsData] = useState([])
  const productData = pageData.data.allContentfulProduct.edges.filter(
    edge =>
      edge.node.productCategory &&
      edge.node.productCategory.name &&
      edge.node.productRoofType &&
      edge.node.productRoofType.name
  )

  const getSeseiProducts = async () => {
    const res = await axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/v1/product/listing",
      data: {
        limit: 1000,
      },
      headers: {
        Authorization:
          "Bearer " +
          "13IMU4ZLF9WyrjRj6JuTfVRwGswQVmfBpO39w7bYvaFT93nbcTKE8PnUBv4bWMrM",
      },
    })
    setSenseiProductsData(res.data.data)
  }

  useEffect(() => {
    getSeseiProducts()
  }, [])

  const syncProducts = () => {
    const senseiData = seseiProductsData.data
    const client = createClient({
      accessToken: "CFPAT-6mLLDRiqRmKYIHJSVBKjT3J-TLCtbXdJE9NaykR0cWA",
    })
    const space = client.getSpace("z75g1qwq4el7")
    senseiData.forEach(element => {
      const senseiWebSku = element.web_sku.slice(3)
      const senseiId = element.id
      const sameProduct = productData.find(
        item => String(item.node.skuNumber) === senseiWebSku
      )
      const sameNameProduct =
        productData.find(item => item.node.title === element.name) || ""
      let realImage =
        element.image_data.find(item => item.is_default === 1) || null
      if (!realImage)
        realImage = element.image_data[element.image_data.length - 1]

      if (sameProduct !== undefined) {
        space
          .then(space => space.getEnvironment("master"))
          .then(environment =>
            environment.getEntry(sameProduct.node.contentful_id)
          )
          .then(entry => {
            entry.fields.senseiProductId["en-US"] = String(senseiId)
            entry.fields.imageSrc["en-US"] = realImage.image_url
            entry.fields.price["en-US"] = Math.round(element.order_total)
            return entry.update()
          })
          .then(entry => {
            console.log(`Entry ${entry.sys.id} updated.`)
            return entry.publish()
          })
          .then(entry => console.log(`Entry ${entry.sys.id} published.`))
          .catch(error => console.error("Error:", error))
      } else {
        if (sameNameProduct === "") {
          space
            .then(space => space.getEnvironment("master"))
            .then(environment =>
              environment.createEntry("product", {
                fields: {
                  title: {
                    "en-US": element.name,
                  },
                  skuNumber: {
                    "en-US": Number(senseiWebSku),
                  },
                  senseiProductId: {
                    "en-US": String(senseiId),
                  },
                  imageSrc: {
                    "en-US": realImage.image_url,
                  },
                  width: {
                    "en-US": String(element.width),
                  },
                  height: {
                    "en-US": String(element.height),
                  },
                  length: {
                    "en-US": String(element.length),
                  },
                  price: {
                    "en-US": Math.round(element.order_total),
                  },
                },
              })
            )
            .then(entry => {
              console.log("created", entry)
              return entry.publish()
            })
            .then(entry => console.log("publish", entry))
            .catch(console.error)
        }
      }
    })
  }

  return (
    <Layout location={pageData.location}>
      <SEO
        title="Shop Metal Buildings – Durable and Customizable Steel Structures for Every Need"
        description="Shop durable and customizable metal buildings from Probuilt Steel Buildings. Explore our range of high-quality steel structures for residential, commercial, and agricultural purposes."
      />
      <SectionPage
        ept="160px"
        epb="60px"
        xpt="140px"
        xpb="40px"
        pt="100px"
        pb="30px"
        bg="#fff"
      >
        <Container>
          <SectionPageTitle textAlign="center">Explore All Building Products</SectionPageTitle>
          <SectionDescription mb="0">
            <p>ProBuilt’s primary mission is to help our customers protect their automobiles, farm equipment, gardening accessories, and so much more by providing the highest quality metal buildings. ProBuilt Steel Buildings are designed to be highly functional, versatile, and meet superior engineering standards. They are the perfect addition to any home or business.</p>
          </SectionDescription>
          <SyncButton onClick={() => syncProducts()}><PrimaryButton text="Explore All Products" /></SyncButton>
        </Container>
      </SectionPage>
      <Products
        location={pageData.location}
        products={productData}
        productCategories={pageData.data.allContentfulProductCategory.edges}
        productRoofTypes={pageData.data.allContentfulProductRoofType.edges}
      />
      <CallToAction />
    </Layout>
  )
}

export default ProductPage

export const pageQuery = graphql`
  query ProductPageQuery {
    allContentfulProduct(sort: { fields: skuNumber }) {
      edges {
        node {
          id
          contentful_id
          skuNumber
          imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          productServices {
            spaceId
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
          tags
        }
      }
    }
    allContentfulProductCategory(
      filter: { filterableCategory: { eq: true } }
      sort: { fields: order }
    ) {
      edges {
        node {
          name
        }
      }
    }
    allContentfulProductRoofType(filter: { filterableType: { eq: true } }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
