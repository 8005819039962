import React from "react"

const DropArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="5"
      viewBox="0 0 8 5"
    >
      <path d="M1,0,5,2,9,0,5,5Z" transform="translate(-1)" />
    </svg>
  )
}

export default DropArrowIcon
