import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryLinkBtn = styled(BaseButton)`	
	color:#062C44;
    background:transparent;	    
	border:none;
    &:before, &:after {
        display:none;
    }
	& .icon{
		> svg{
			fill:#062C44;
		}
	}
	
	&:hover{
		color:#062C44;	
        background:transparent;	    
		border:none;
		& .icon{
			> svg{
				fill:#062C44;
			}
		}
	}
`
const PrimaryLinkButton = (props) => {
	const { icon, iconAfter, text, size } = props;
	return(
		<PrimaryLinkBtn className={`btn ${size ? `btn-${size}` : ''}`}>
			{ iconAfter ? (
				<>
					{ text && <span className='text'>{text}</span>}
					{ iconAfter && <span className='icon'>{iconAfter}</span>}
				</>
				):(
					<>
						{ icon && <span className='icon'>{icon}</span>}
						{ text && <span className='text'>{text}</span>}
					</>
				)
			}
		</PrimaryLinkBtn>
	)
}


export default PrimaryLinkButton
