import React, { useState } from "react"
import styled from "styled-components"
import DropIcon from "../Icons/DropIcon"
import CloseIcon from "../Icons/CloseIcon"
import PriceSortDropdown from "../DropDown/price-dropdown"

const PriceDrop = styled.div`
  position: relative;
  .dropdown-toggle {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    .text {
      font-size: 14px;
      line-height: 20px;
      color: #8cb7ce;
      white-space: nowrap;
      + .icon {
        margin-left: 5px;
      }
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      @media (min-width: 576px) {
        width: 20px;
        height: 20px;
      }
      svg {
        fill: #8cb7ce;
      }
    }
    &:hover{
      .text {
        color: #062c44;
      }
      .icon {
        svg {
          fill: #062c44;
        }
      }
    }
  }
`

const PriceDropdown = ({ dir, onChange }) => {
  const [isDropDown, setDropDown] = useState(false)

  function hangleDropDown(index) {
    if (isDropDown === index) {
      return setDropDown(null)
    }
    setDropDown(index)
  }

  return (
    <PriceDrop>
      <button
        aria-label="span"
        className="dropdown-toggle"
        onClick={() => hangleDropDown(1)}
      >
        <span className="text">Sort By</span>
        <span className="icon">
          {isDropDown === 1 ? <CloseIcon /> : <DropIcon />}
        </span>
      </button>
      <PriceSortDropdown
        isActive={isDropDown === 1 ? true : false}
        dir={dir}
        onChange={onChange}
        onClose={() => setDropDown(0)}
      />
    </PriceDrop>
  )
}

export default PriceDropdown
